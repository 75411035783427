<template>
    <div>
        <van-dropdown-menu active-color="#00de93">
            <van-dropdown-item @change="handleChange" v-model="course_cate_id" :options="courses" />
            <!-- <van-dropdown-item v-if="appid == 'X61CK1'" @change="handleChange" v-model="recruit_name" :options="times" /> -->
            <!-- <van-dropdown-item v-if="appid == 'X61CK1'" @change="handleChange" v-model="recruit_grade" :options="grades" /> -->
            <van-dropdown-item @change="handleChange" v-model="studio_id" :options="studios" />
        </van-dropdown-menu>
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            >
            <div class="recruit-list-box">
                <div class="recruit-list-left" style="width:47%">
                    <div v-for="(item,index) in list" :key="index" v-show="index%2 === 0" @click="toDetail(item)" class="recruit-card">
                        <img :src="item.recruit_cover">
                        <div class="recruit-card-box">
                            <div class="recruit-card-title">{{item.recruit_name}}</div>
                            <div class="recruit-card-number">报名人数:{{item.reserve_count}}/{{item.recruit_total}}人</div>
                            <div class="recruit-card-number">报名校区:{{item.recruit_studio_name}}</div>
                        </div>
                        <van-tag class="recruit-van-tag" type="success">￥{{item.sale_price}}</van-tag>
                        <div @click.stop v-if="item.recruit_last_sec > 0" class="recruit-card-bg">
                            <van-count-down class="recruit-vant-count-down" :time="item.recruit_last_sec" format="DD::HH:mm:ss" @finish="toFinish(item)">
                                <template #default="timeData">
                                    <span class="block" v-if="timeData.days">{{ timeData.days }}</span>
                                    <span class="colon" v-if="timeData.days">:</span>
                                    <span class="block">{{ timeData.hours < 10 ?'0'+timeData.hours:timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block">{{ timeData.minutes<10 ?'0'+timeData.minutes:timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block">{{ timeData.seconds<10?'0'+timeData.seconds:timeData.seconds }}</span>
                                </template>
                            </van-count-down>
                        </div>
                    </div>
                </div>
                <div class="recruit-list-right" style="width:47%">
                    <div v-for="(item,index) in list" :key="index" v-show="index%2 !== 0" @click="toDetail(item)" class="recruit-card">
                        <img :src="item.recruit_cover">
                        <div class="recruit-card-box">
                            <div class="recruit-card-title">{{item.recruit_name}}</div>
                            <div class="recruit-card-number">报名人数:{{item.reserve_count}}/{{item.recruit_total}}人</div>
                            <div class="recruit-card-number">报名校区:{{item.recruit_studio_name}}</div>
                        </div>
                        <van-tag class="recruit-van-tag" type="success">￥{{item.sale_price}}</van-tag>
                        <div @click.stop v-if="item.recruit_last_sec > 0" class="recruit-card-bg">
                            <van-count-down class="recruit-vant-count-down" :time="item.recruit_last_sec" format="DD::HH:mm:ss" @finish="toFinish(item)">
                                <template #default="timeData">
                                    <span class="block" v-if="timeData.days">{{ timeData.days }}</span>
                                    <span class="colon" v-if="timeData.days">:</span>
                                    <span class="block">{{ timeData.hours < 10 ?'0'+timeData.hours:timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block">{{ timeData.minutes<10 ?'0'+timeData.minutes:timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block">{{ timeData.seconds<10?'0'+timeData.seconds:timeData.seconds }}</span>
                                </template>
                            </van-count-down>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        <copyright v-show="!searchLoading" class="copyright-recruit" />
        <loadings :loading='searchLoading' />

        <!-- 底部导航栏 -->
        <Tabbar v-show='!loading' />
    </div>
</template>

<script>
    import { Col, Row, List, Tag, Overlay, CountDown, Empty } from 'vant'
    import { DropdownMenu, DropdownItem } from 'vant'
    import Loadings from '@/components/loading'
    import Tabbar from '@/components/Tabbar'
    import Copyright from '@/components/Copyright'
    import dayjs from 'dayjs'
    export default {
        name:'recruit',
        data() {
            return {
                course_cate_id: '',
                studio_id: '',
                recruit_name:'',
                recruit_grade:'',
                courses: [
                    { text: '全部科目', value: '' }
                ],
                studios: [
                    { text: '全部校区', value: '' }
                ],
                years:[
                  { text: '全部年龄段', value: '' },
                  { text: 'K1-2', value: 'K1-2' },
                  { text: 'K3', value: 'K3' },
                  { text: 'G1-3', value: 'G1-3' },
                ],
                grades:[
                    { text: '全部年级', value: '' },
                    { text: '1年级', value: 'G1' },
                    { text: '2年级', value: 'G2' },
                    { text: '3年级', value: 'G3' },
                    { text: '4年级', value: 'G4' },
                    { text: '5年级', value: 'G5' },
                ],
                times:[
                    { text: '全部日期', value: '' },
                    { text: '周一', value: '周一' },
                    { text: '周二', value: '周二' },
                    { text: '周三', value: '周三' },
                    { text: '周四', value: '周四' },
                    { text: '周五', value: '周五' },
                    { text: '周日', value: '周日' },
                ],
                activeBar:1,
                show:true,
                loading:false,
                finished:false,
                searchLoading:true,
                list: [],
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
                time: 30 * 60 * 60 * 1000,
                appid:''
            }
        },
        components: {
            Copyright,
            [DropdownItem.name]:DropdownItem,
            [DropdownMenu.name]:DropdownMenu,
            [Tag.name]:Tag,
            [Col.name]:Col,
            [Row.name]:Row,
            [List.name]:List,
            [Empty.name]:Empty,
            [Overlay.name]:Overlay,
            [CountDown.name]:CountDown,
            Tabbar:Tabbar,
            loadings:Loadings,

        },
        computed: {
        },
        created () {
            this.appid = localStorage.getItem('appid')
            this.getStudio()
        },
        methods: {
            getList(){
                let obj = {
                    'per-page': this.pageSize, 
                    page: this.pageCurrent,
                    course_cate_id:this.course_cate_id
                }
                // if(this.appid == 'X61CK1'){
                //     obj.recruit_name = this.recruit_name
                //     obj.recruit_grade = this.recruit_grade
                // }else{
                //     obj.studio_id = this.studio_id
                // }
                obj.studio_id = this.studio_id
                this.$api.recruit_index(obj)
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            getStudio(){
                this.$api.recruit_filter()
                .then(res=>{
                    this.studios = res.data.studio_id
                    this.courses = res.data.course_cate_id
                })
            },
            onLoad() {
                this.getList()
            },
            toDetail(item){
                this.$router.push({path:'/recruitDetail',query:{recruit_id:item.recruit_id,appid:localStorage.getItem('appid')}})
            },
            toFinish(item){
                item.recruit_last_sec = 0
            },
            handleChange(){
                this.searchLoading = true
                this.pageCurrent = 1
                this.getList()
            },
            isLast(index){
                let num = index +1
                if(num == this.list.length){
                    if( num%2 == 0 ){
                        return false
                    }else{
                        return true
                    }
                }else{
                    return false
                }
            }
        },
    }
</script>

<style lang="less">
    .colon {
        display: inline-block;
        margin: 0 4px;
        color:#bbb;
    }
    .block {
        display: inline-block;
        width: 22px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #bbb;
        border-radius: 4px;
    }
    .recruit-card{
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        margin: 10px 0;
        img{
            width: 100%;
            height: 90px;
        }
        &-box{
            padding: 2.5px 2.5px 2.5px 2.5px;
        }
        &-title{
            word-break: break-all;
        }
        &-number{
            font-size: 12px;
            color: #777;
            margin-top: 5px;
        }
        &-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .recruit-van-row{
        width: 95%;
        margin: 0 auto;
    }
    .recruit-van-col{
        margin: 2.5px 0;
    }
    .recruit-van-col-left{
        padding-right: 4PX;
    }
    .recruit-van-tag{
        position: absolute;
        right: 0;
        top: 74px;
        border-radius: initial;
        border-top-left-radius:2px;
    }
    .recruit-vant-count-down{
        position: relative;
        z-index: 11;
    }
    .recruit-list-box{
        display: flex;
        justify-content: space-evenly;
    }
    .copyright-recruit{
        margin-bottom: 60px;
    }
</style>